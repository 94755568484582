/* eslint-disable react-hooks/exhaustive-deps */
import 'react-app-polyfill/ie11'; // For IE 11 support
import 'react-app-polyfill/stable';
import './polyfill';
import './i18n';
import './scss/style.scss';

import React, { Suspense, useEffect } from 'react';
import i18next from 'i18next';
import { useTranslation } from 'react-i18next';
import { BrowserRouter, Switch, Route, Redirect } from 'react-router-dom';
import { PropTypes } from 'prop-types';
import { ErrorBoundary } from 'react-error-boundary';
import { useAuth0 } from '@auth0/auth0-react';
import { useDispatch, useSelector } from 'react-redux';
import LoadingSpinner from './components/LoadingSpinner';
import { consts, urls } from './utils/config';
import { Axios, getRefreshToken, getStorageItem, getToken, setStorageItem } from './utils/ApiClient';
import Page500 from './views/pages/Page500';
import { isAuthenticated as userAuthenticated, setAuthenticated, setUser, getAppSettings, setAuthorized } from './app/appSlice';
import { isJsonString } from './utils/Helpers';
import Page403 from './views/pages/Page403';
import { alert } from './app/messageSlice';

const TheLayout = React.lazy(() => import('./containers/TheLayout'));
const Login = React.lazy(() => import('./views/pages/Login'));
const ForgotPassword = React.lazy(() => import('./views/pages/ForgotPassword'));
const ResetPassword = React.lazy(() => import('./views/pages/ResetPassword'));

const Auth0Login = ({ organization }) => {
  const { loginWithRedirect } = useAuth0();

  const params = organization
    ? { authorizationParams: { organization } }
    : { };

  loginWithRedirect(params).catch((e) => <Page500 error={e} onInit />);

  return <span>{`redirecting to Auth0 - ${getStorageItem(consts.authSettings)?.domain}`}</span>;
};

Auth0Login.propTypes = {
  organization: PropTypes.string
};

Auth0Login.defaultProps = {
  organization: undefined
};

const log = (error) => {
  if (!process.env.NODE_ENV || process.env.NODE_ENV === 'development') {
    // eslint-disable-next-line no-console
    console.log(error);
  } else {
    // do nothing
  }
};

export const SecureAppUsingAuth0 = ({ organization }) => {
  const { t } = useTranslation(['translation']);
  const dispatch = useDispatch();
  const { user, isAuthenticated, isLoading, error, getAccessTokenSilently, logout } = useAuth0();

  const doLogout = (config) => {
    logout({ logoutParams: { returnTo: `${window.location.origin}` } });
    sessionStorage.clear();
    const controller = new AbortController();
    controller.abort();
    return {
      ...config,
      signal: controller.signal
    };
  };

  Axios.interceptors.request.use(async (config) => {
    if (config?.data?.logoutRequest && isAuthenticated) {
      return doLogout(config);
    }

    try {
      const { audience } = getStorageItem(consts.authSettings) || {};
      const { culture } = getStorageItem(consts.userdata) || {};

      const accessToken = await getAccessTokenSilently({
        authorizationParams: {
          audience,
          scope: 'read:current_user',
        },
      });

      return {
        ...config,
        headers: {
          Authorization: `Bearer ${accessToken}`,
          culture,
        }
      };
    } catch (e) {
      log(e);
      return doLogout(config);
    }
  });

  Axios.interceptors.response.use(
    (response) => response,
    (e) => {
      if (e.response?.status === 403) {
        dispatch(alert(e.response.messages?.join(', ') ?? t('messages.Forbidden')));
        dispatch(setAuthorized(false));
      }
      if (e.response?.status === 401) {
        sessionStorage.clear();
        window.location.replace('/');
      }
      return e;
    }
  );

  useEffect(() => {
    if (isAuthenticated && user) {
      dispatch(setAuthenticated({
        token: 'Auth0',
        refreshToken: 'Auth0',
        userName: user.email
      }));
      dispatch(setUser({ ...user }));
      dispatch(getAppSettings());
      i18next.changeLanguage();
    } else {
      dispatch(setAuthenticated());
    }
  }, [isAuthenticated, user]);

  if (isLoading) return <LoadingSpinner absolute />;
  if (error) {
    if (error.error === 'access_denied') return <Page403 message="User has no access to this organization" onInit />;
    return <Page500 error={error} onInit />;
  }

  return (
    <BrowserRouter>
      <Suspense fallback={<LoadingSpinner absolute />}>
        <Switch>
          <Route exact path="/completeregistration" name="Complete registration" render={({ location }) => <ResetPassword completeRegistration location={location} />} />
          <Route
            render={({ location }) => (
              isAuthenticated
                ? (
                  <ErrorBoundary FallbackComponent={Page500}>
                    <TheLayout location={location} />
                  </ErrorBoundary>
                )
                : <Auth0Login organization={organization} />
            )}
          />
        </Switch>
      </Suspense>
    </BrowserRouter>
  );
};

SecureAppUsingAuth0.propTypes = {
  organization: PropTypes.string
};

SecureAppUsingAuth0.defaultProps = {
  organization: ''
};

let signinRefreshing = false;

export const SecureAppUsingAffinity = () => {
  const authenticated = useSelector(userAuthenticated);
  const doLogout = () => {
    sessionStorage.clear();
    window.location.replace('/login');
  };

  const delayRequest = (originalRequest, ms) => new Promise((resolve) => {
    setTimeout(() => resolve(Axios.request(originalRequest)), ms);
  });

  Axios.interceptors.response.use(
    (response) => response,
    async (error) => {
      if (error.response?.status === 401) {
        const originalRequest = error.config;

        if (originalRequest._retry) {
          doLogout();
          return Promise.reject(error);
        }

        originalRequest._retry = true;

        try {
          if (!signinRefreshing) {
            signinRefreshing = true;
            const options = {
              method: 'POST',
              headers: { Authorization: getToken() },
              data: { token: getRefreshToken() },
              url: urls.signinrefresh
            };
            const result = await Axios.request(options);
            setStorageItem(consts.authentication, result.data);
            signinRefreshing = false;
          }

          try {
            if (isJsonString(originalRequest?.data)) {
              originalRequest.data = JSON.parse(originalRequest.data);
            }
          } catch (e) {
            log(e);
          }
          originalRequest.headers = {
            ...originalRequest.headers,
            Authorization: getToken()
          };
          return await delayRequest(originalRequest, 1000);
        } catch (e) {
          log(e);
        }
      }

      return Promise.reject(error);
    }
  );

  Axios.interceptors.request.use(async (config) => {
    const accessToken = getToken();
    const { culture } = getStorageItem(consts.userdata) || {};
    return { ...config, headers: { Authorization: accessToken, culture } };
  });

  return (
    <BrowserRouter>
      <Suspense fallback={<LoadingSpinner absolute />}>
        <Switch>
          <Route exact path="/login" name="Login Page" render={({ location }) => <Login location={location} />} />
          <Route exact path="/forgotpassword" name="Forgot Password" render={() => <ForgotPassword />} />
          <Route exact path="/completeregistration" name="Complete registration" render={({ location }) => <ResetPassword completeRegistration location={location} />} />
          <Route exact path="/resetpassword" name="Reset Password" render={({ location }) => <ResetPassword location={location} />} />
          <Route
            render={({ location }) => (
              authenticated
                ? (
                  <ErrorBoundary FallbackComponent={Page500}>
                    <TheLayout location={location} />
                  </ErrorBoundary>
                )
                : <Redirect to={{ pathname: '/login', state: { from: location?.pathname, search: location?.search } }} />
            )}
          />
        </Switch>
      </Suspense>
    </BrowserRouter>
  );
};
